import { ApplicationDataState } from '../application/application.model';
import { BoxAutoDTO } from '../application/insurances/insurances.model';
import { centsToEuros } from './currency.helper';
import { PartOrPro } from '../application/part-pro-choice/part-pro-choice.model';

export const getBoxAutoFromProject = (applicationData: ApplicationDataState): BoxAutoDTO => {
  return {
    webstoreId: applicationData.dealer?.webStoreId,
    vehicle: {
      amount: centsToEuros(applicationData.project?.vehicle?.price),
      accessoriesAmount: centsToEuros(applicationData.project?.vehicle?.accessoriesAmount),
      optionsAmount: centsToEuros(applicationData.project?.vehicle?.optionsAmount),
      mileage: applicationData.project?.vehicle?.mileage,
      catalogEntryId: applicationData.project?.vehicle?.catalogEntryId,
      family: applicationData.project?.vehicle?.family,
      energy: applicationData.project?.vehicle?.energy,
      vin: applicationData.project?.vehicle?.vin,
      commercial: applicationData.project?.vehicle?.commercial,
      model: applicationData.project?.vehicle?.model,
      brand: applicationData.project?.vehicle?.brand,
      isPro: applicationData.partOrPro === PartOrPro.PRO,
      catalogId: applicationData.project?.vehicle?.catalogId,
      isVatChainBroken: applicationData.project?.vehicle?.vatChainBroken,
      version: applicationData.project?.vehicle?.version,
      firstRegistrationDate: applicationData.project?.vehicle?.registrationFirstDate,
      secondHand: applicationData.project?.vehicle?.secondHand || false
    },
    product: {
      productType: applicationData.project?.product?.code,
      deposit: Number(centsToEuros(applicationData.project?.downPayment)),
      duration: applicationData.project?.duration,
      mileage: applicationData.project?.subscribedMileage
    }
  };
};
