import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatNumber } from '@angular/common';
import { NoValue } from 'ngx-mobilite-material';

@Injectable({
  providedIn: 'root'
})
export class CurrencyFormatService {
  thousandSeparator: string;
  decimalSeparator: string;

  constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    this.thousandSeparator = Intl.NumberFormat(this.locale)
      .format(11111)
      .replace(/\p{Number}/gu, '');
    this.decimalSeparator = Intl.NumberFormat(this.locale)
      .format(1.1)
      .replace(/\p{Number}/gu, '');
  }

  formatInputCurrency(value: string): string {
    return formatNumber(this.currencyToNumber(value), this.locale, '.2');
  }

  formatControlCurrency(value: string): string {
    return formatNumber(this.currencyToNumber(value), this.locale, '.2').replaceAll(this.thousandSeparator, '');
  }

  reverseFormatCurrency(value: string): string {
    const numberDecimal = value.split(this.decimalSeparator);
    return this.formatNumberWithDecimal(
      String(numberDecimal[0]).replace(new RegExp('\\' + this.thousandSeparator, 'g'), ''),
      numberDecimal[1]
    );
  }

  currencyToNumber(value: string | NoValue): number {
    return Number((value || '0').replace(this.decimalSeparator, '.').replaceAll(this.thousandSeparator, ''));
  }

  private formatNumberWithDecimal(number: string, decimal: string | NoValue): string {
    return `${number}${decimal ? this.decimalSeparator : ''}${decimal || ''}`;
  }
}
