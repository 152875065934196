import { ApplicationDataState, Dealer, Project, Vehicule } from '../application/application.model';
import {
  BankCode,
  BankDetailsDataSubscription,
  BorrowerCompany,
  BorrowerCompanyCommon,
  CivilitySubscription,
  CoHolderInformation,
  ContactDataSubscription,
  EmployerSubscription,
  ExpensesSubscription,
  HolderEmploymentSituationDataSubscription,
  HolderInformation,
  HousingDataSubscription,
  IdentitySubscription,
  MailingAddressDataSubscription,
  MaritalStatus,
  PersonalIncomeSubscription,
  SubmitSubscription
} from '../application/data-transmission/data-transmission.model';
import {
  Civility,
  FamilySituation,
  PersonalData,
  PersonalDataCoHolder
} from '../application/personal-data-form/personal-data-form.model';
import { BirthInformationData } from '../application/birth-information-form/birth-information-form.model';
import { dayMonthYearToString, monthYearToString } from './date.helper';
import { Locality } from '../application/birth-information/localities.service';
import { HousingData } from '../application/housing-form/housing-form.model';
import { MailingAddressData } from '../application/mailing-address-form/mailing-address-form.model';
import { EmploymentSituationData } from '../application/employment-situation-form/employment-situation-form.model';
import { EmployeeFormData } from '../application/employment-situation-form/employee-form/employee-form-model';
import {
  CoHolderCompanyInformation,
  CompanyInformationData,
  HolderCompanyInformation
} from '../application/company/company.model';
import { PersonalIncomes } from '../application/personal-incomes-form/personal-incomes.model';
import { AccountOwner, BankDetails } from '../application/bank-details/bank-details.component.model';
import { AloneOrCoHolder } from '../application/alone-co-holder-choice/alone-co-holer-choice.model';
import { Costs } from '../application/costs-form/costs.component.model';
import { KeyValue } from 'ngx-mobilite-material';

export const getSubscriptionScoringDataFromApplicationData = (
  applicationData: ApplicationDataState
): SubmitSubscription => {
  const dealerInformation = applicationData.dealer as Dealer;
  return {
    context: {
      country: 'FRA',
      currency: 'EUR',
      channel: 'EXT',
      taxExcluded: false
    },
    holder: getHolderInformations(applicationData),
    coHolder:
      applicationData.aloneOrCoHolder === AloneOrCoHolder.CO_HOLDER ? getCoHolderInformations(applicationData) : null,
    project: applicationData.project
      ? {
          ...(applicationData.project as Project),
          services: applicationData.project.services || [],
          vehicle: {
            ...applicationData.project.vehicle,
            energy: applicationData.project.vehicle?.catalogEntryId ? null : applicationData.project.vehicle?.energy
          } as Vehicule
        }
      : null,
    loginId: dealerInformation.loginId,
    webstoreId: dealerInformation.webStoreId,
    externalId: applicationData.externalId as string
  };
};

const getHolderInformations = (applicationData: ApplicationDataState): HolderInformation => {
  return {
    status: 'Natural', // Natural : physical person, Legal: For professional reason. For the moment only Natural is developed
    identity:
      applicationData.identity?.holder || applicationData.birthInformation?.holder
        ? getPersonalContactSubscriptionFromPersonalContactAndBirthInfo(
            applicationData.identity?.holder as PersonalData,
            applicationData.birthInformation?.holder as BirthInformationData
          )
        : null,
    contactData: applicationData.identity?.holder
      ? getContactDataSubscriptionFromIdentity(applicationData.identity?.holder)
      : null,
    household: applicationData.housingData
      ? getHouseholdFromHousingData(applicationData.housingData as HousingData)
      : null,
    address: applicationData.mailingAddress?.holder
      ? getAddressSubscriptionFromMailingAddress(applicationData.mailingAddress?.holder)
      : null,
    personalData: {
      maritalStatus: MaritalStatus[applicationData.identity?.holder?.familySituation as FamilySituation],
      nbChildren: applicationData.householdComposition?.nbChildren?.toString()
    },
    employment: applicationData.employmentSituation?.holder
      ? {
          ...getEmploymentDataSubscriptionFromEmploymentData(applicationData.employmentSituation.holder),
          borrowerCompany: !applicationData.employmentSituation.holder.contract
            ? getBorrowerCompanyFromCompanyInformation(
                applicationData.companyInformation?.holder as CompanyInformationData<HolderCompanyInformation>
              )
            : null
        }
      : null,
    bankAccount: applicationData.bankDetails
      ? getBankDetailsInformations(applicationData.bankDetails as BankDetails)
      : null,
    coBorrower: {
      type: applicationData.coHolderType ? applicationData.coHolderType : 'N'
    },
    incomes: applicationData.personalIncomes
      ? getIncomesFromPersonalIncomes(applicationData.personalIncomes as PersonalIncomes)
      : null,
    expenses: applicationData.costs ? getExpensesFromCosts(applicationData.costs as Costs) : []
  };
};

const getCoHolderInformations = (applicationData: ApplicationDataState): CoHolderInformation => {
  return {
    identity:
      applicationData.identity?.coHolder || applicationData.birthInformation?.coHolder
        ? getPersonalContactSubscriptionFromPersonalContactAndBirthInfo(
            applicationData.identity?.coHolder as PersonalData,
            applicationData.birthInformation?.coHolder as BirthInformationData
          )
        : null,
    contactData: applicationData.identity?.coHolder
      ? getContactDataSubscriptionFromIdentity(applicationData.identity?.coHolder)
      : null,
    address: applicationData.mailingAddress?.coHolder
      ? getAddressSubscriptionFromMailingAddress(applicationData.mailingAddress?.coHolder)
      : null,
    employment: applicationData.employmentSituation?.coHolder
      ? {
          ...getEmploymentDataSubscriptionFromEmploymentData(applicationData.employmentSituation.coHolder),
          borrowerCompany: !applicationData.employmentSituation.coHolder.contract
            ? getBorrowerCompanyCommonFromCompanyInformation(
                applicationData.companyInformation?.coHolder as CompanyInformationData<CoHolderCompanyInformation>
              )
            : null
        }
      : null
  };
};

const getPersonalContactSubscriptionFromPersonalContactAndBirthInfo = (
  personnalData: PersonalData,
  birthInformation: BirthInformationData
): IdentitySubscription => {
  return {
    firstName: personnalData.firstName,
    lastName: personnalData.lastName,
    maidenName:
      personnalData.civility === Civility.MADAM &&
      personnalData.familySituation !== FamilySituation.SINGLE &&
      !personnalData.birthName
        ? personnalData.lastName
        : personnalData.birthName,
    title:
      personnalData.civility === Civility.MADAM && personnalData.familySituation === FamilySituation.SINGLE
        ? CivilitySubscription.MADEMOISELLE
        : CivilitySubscription[personnalData.civility],
    birthDate: dayMonthYearToString(birthInformation.birthDate),
    birthPlace: {
      cityInseeCode: birthInformation.franceChosen
        ? (birthInformation.franceChosen.birthCity as Locality).inseeCode
        : '',
      city: birthInformation.franceChosen
        ? (birthInformation.franceChosen.birthCity as Locality).name
        : birthInformation.otherChosen
        ? birthInformation.otherChosen.birthCity
        : '',
      country: birthInformation.franceChosen
        ? birthInformation.birthCountryChoice
        : birthInformation.otherChosen
        ? birthInformation.otherChosen.birthCountry
        : ''
    },
    identityDocuments: {
      nationality: 'FR',
      type: 'NUL'
    }
  };
};

const getContactDataSubscriptionFromIdentity = (
  identity: PersonalData | PersonalDataCoHolder
): ContactDataSubscription => {
  return {
    emailAddress: identity.mail,
    mobilePhoneNumber: phoneFormat(identity.mobile)
  };
};

const getHouseholdFromHousingData = (housingData: HousingData): HousingDataSubscription => {
  return {
    type: housingData.housing,
    seniority: housingData.since ? monthYearToString(housingData.since) : null
  };
};

const getAddressSubscriptionFromMailingAddress = (address: MailingAddressData): MailingAddressDataSubscription => {
  return {
    cityInseeCode: (address.zipCode as Locality)?.inseeCode,
    streetName: address.address,
    streetComplement: address.additionalAddress || null
  };
};

const getEmploymentDataSubscriptionFromEmploymentData = (
  employmentData: EmploymentSituationData
): Omit<HolderEmploymentSituationDataSubscription, 'borrowerCompany'> => {
  return {
    contract:
      employmentData.contract && 'contractType' in employmentData.contract
        ? employmentData.contract.contractType?.key
        : null,
    profession: employmentData?.profession.key,
    businessSector: employmentData.activity,
    seniority: employmentData.contract?.since ? monthYearToString(employmentData.contract?.since) : null,
    employer:
      employmentData.contract && 'employerName' in employmentData.contract
        ? getEmployerInformation(employmentData.contract)
        : null
  };
};

const getBorrowerCompanyFromCompanyInformation = (
  companyInformation: CompanyInformationData<HolderCompanyInformation>
): BorrowerCompany => {
  return {
    ...getBorrowerCompanyCommonFromCompanyInformation(companyInformation),
    legalForm: companyInformation.information?.legalStatus?.key,
    apeCode: companyInformation.information?.nafCode,
    creationDate: companyInformation.information?.since
      ? monthYearToString(companyInformation.information?.since)
      : null,
    capital: companyInformation.information?.capital,
    businessAssets: companyInformation.information?.businessAssets,
    accountant: {
      name: companyInformation.information?.companyName,
      address: {
        cityInseeCode: (companyInformation.contactDetails?.zipCode as Locality)?.inseeCode,
        city: companyInformation.contactDetails?.city,
        streetName: companyInformation.contactDetails?.address,
        streetComplement: companyInformation.contactDetails?.additionalAddress
      },
      contactData: {
        phoneNumber: phoneFormat(companyInformation.contactDetails?.phone)
      }
    },
    cityRegistration: companyInformation.information?.transplantCity,
    nature: companyInformation.information?.activityType
  };
};

const getBorrowerCompanyCommonFromCompanyInformation = (
  companyInformation: CompanyInformationData<HolderCompanyInformation | CoHolderCompanyInformation>
): BorrowerCompanyCommon => {
  return {
    identifierValue: companyInformation.sirenNumber,
    employeesNumber: companyInformation.information?.employeeNumber,
    premises: companyInformation.information?.premises,
    contactData: {
      landlinePhoneNumber: phoneFormat(companyInformation.contactDetails?.phone)
    }
  };
};

const getBankDetailsInformations = (bankDetails: BankDetails): BankDetailsDataSubscription => {
  return {
    holder: bankDetails.isJointAccount ? AccountOwner.JOINT_ACCOUNT : bankDetails.accountOwner,
    bic: bankDetails.bic,
    iban: bankDetails.iban,
    levyDay: bankDetails.debitingDate?.toString(),
    seniority: bankDetails.openedSince ? monthYearToString(bankDetails.openedSince) : null,
    bankCode: bankDetails.bic ? getBankCodeFromBic(bankDetails.bic) : 9
  };
};

const getIncomesFromPersonalIncomes = (personalIncomes: PersonalIncomes): PersonalIncomeSubscription[] => {
  const incomes: PersonalIncomeSubscription[] = [];
  if (personalIncomes.holderIncome) {
    incomes.push({ type: 'salary', amount: personalIncomes.holderIncome });
  }
  if (personalIncomes.socialBenefits) {
    incomes.push({ type: 'welfare_state', amount: personalIncomes.socialBenefits });
  }
  if (personalIncomes.coHolderIncome) {
    incomes.push({ type: 'coholder_incomes', amount: personalIncomes.coHolderIncome });
  }
  if (personalIncomes.otherIncome) {
    incomes.push({
      type: 'other',
      amount: Number(personalIncomes.otherIncome).toString()
    });
  }
  return incomes;
};

const getExpensesFromCosts = (costs: Costs): ExpensesSubscription[] => {
  const expenses: ExpensesSubscription[] = [];
  if (costs.alimony) {
    expenses.push({ type: 'child_support_maintenance', amount: costs.alimony });
  }
  if (costs.housingTax) {
    expenses.push({ type: 'housing', amount: costs.housingTax });
  }
  if (costs.otherTaxes) {
    expenses.push({ type: 'other_expenses', amount: costs.otherTaxes });
  }
  return expenses;
};

const phoneFormat = (phone: string | undefined): string | undefined => {
  if (phone?.startsWith('0')) {
    return '+33'.concat(phone.substring(1));
  }
  return phone;
};

const getEmployerInformation = (contract: EmployeeFormData): EmployerSubscription => {
  return {
    name: contract.employerName,
    address: {
      cityInseeCode: (contract.employerZipCode as Locality).inseeCode,
      streetName: contract.employerAddress,
      country: (contract.country as KeyValue)?.key || (contract.country as string),
      city: contract.employerCity,
      streetComplement: null
    },
    contactData: {
      phoneNumber: phoneFormat(contract.employerPhone || '+33123456789')
    },
    firstEmployment: contract.isFirstJob,
    previousEmployerDuration: contract.isFirstJob ? null : contract.previousEmployedDuration,
    previousEmployerName: contract.isFirstJob ? null : contract.previousEmployerName
  };
};

const getBankCodeFromBic = (bic: string): number => {
  const bankCode = Object.entries(BankCode).find(([key]) => key === bic.substring(0, 4).toUpperCase());

  return bankCode?.length ? (bankCode[1] as number) : 9;
};
