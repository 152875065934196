<div class="row center text-center space-top-2 summary-title">{{ 'application.summary.reminder' | translate }}</div>
<div class="content" *ngIf="projectSimulation$ | async as projectSimulation; else loader">
  <ml-summary [isVat]="isVat" [projectSimulation]="projectSimulation" [insuranceASM]="insuranceASM" [insurancePAK]="insurancePAK" [insurancePFI]="insurancePFI"></ml-summary>
</div>
<fol-legal-notice />
<ng-template #loader class="loader">
  <ml-summary-skeleton [isVat]="isVat"/>
</ng-template>

<ng-template #stickyButtonWrapper>
  <a mat-flat-button color="secondary" [routerLink]="[backRoute]" id="backButton">
    <mat-icon matPrefix>keyboard_arrow_left</mat-icon>
    {{"application.personal-data.buttons.back" | translate}}
  </a>
  <a mat-flat-button color="primary" [routerLink]="['../user-info-modification']" id="confirmButtom" class="modify-button">
    {{ "application.summary.buttons.confirm" | translate }}
  </a>
</ng-template>

