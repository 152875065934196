import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { urlJoin } from '../helpers/url.helper';
import { map, Observable } from 'rxjs';
import {
  ApplicationDataState,
  ApplicationDataStateDTO,
  LiberalProfessionFormDataDTO,
  Service
} from './application.model';
import { strToDayMonthYear, strDateToMonthYear } from '../helpers/date.helper';
import { HolderCompanyInformation } from './company/company.model';
import { LiberalProfessionFormData } from './employment-situation-form/liberal-profession-form/liberal-profession-form.model';
import { Housing } from './housing-form/housing-form.model';
import { ApplicationStore } from './application-store.service';
import { NoValue } from 'ngx-mobilite-material';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  url = '';
  constructor(
    private readonly httpClient: HttpClient,
    readonly appConfig: AppConfig,
    private readonly applicationStore: ApplicationStore
  ) {
    this.url = urlJoin(appConfig.baseWsURL(), 'contracts');
  }

  getContract(contract: string, token: string): Observable<ApplicationDataState> {
    return this.httpClient.get<ApplicationDataStateDTO>(`${this.url}/${contract}?token=${token}`).pipe(
      map(contractDTO => {
        const { personalIncome, ...otherData } = contractDTO;
        const contract = { ...otherData } as ApplicationDataState;
        this.setBirthInformation(contract.birthInformation, contractDTO.birthInformation);
        this.setHousingData(contract.housingData, contractDTO.housingData);
        this.setCompanyInformation(contract.companyInformation, contractDTO.companyInformation);
        this.setStudentData(contract.employmentSituation, contractDTO.employmentSituation);
        this.setLiberalData(contract.employmentSituation, contractDTO.employmentSituation);
        if (personalIncome) {
          contract.personalIncomes = { ...personalIncome };
        }
        this.setIncomeData(contract.personalIncomes, contractDTO.personalIncome);
        this.setBankDetails(contract.bankDetails, contractDTO.bankDetails);
        contract.dealer = contractDTO.dealer;
        contract.project = contractDTO.project;
        this.setPreSelectedServices(contractDTO.project?.services);
        return contract;
      })
    );
  }

  private setBirthInformation(
    birthInformation: ApplicationDataState['birthInformation'],
    birthInformationDTO: ApplicationDataStateDTO['birthInformation']
  ): void {
    if (birthInformationDTO?.holder && birthInformation?.holder) {
      birthInformation.holder.birthDate = strToDayMonthYear(birthInformationDTO.holder.birthDate);
    }
    if (birthInformationDTO?.coHolder && birthInformation?.coHolder) {
      birthInformation.coHolder.birthDate = strToDayMonthYear(birthInformationDTO.coHolder.birthDate);
    }
    if (
      birthInformationDTO?.holder?.otherNationalityChosen?.nationality &&
      birthInformation?.holder?.otherNationalityChosen
    ) {
      birthInformation.holder.otherNationalityChosen.nationality =
        birthInformationDTO.holder.otherNationalityChosen.nationality;
    }
    if (
      birthInformationDTO?.coHolder?.otherNationalityChosen?.nationality &&
      birthInformation?.coHolder?.otherNationalityChosen
    ) {
      birthInformation.coHolder.otherNationalityChosen.nationality =
        birthInformationDTO.coHolder.otherNationalityChosen.nationality;
    }
  }

  private setHousingData(
    housingData: ApplicationDataState['housingData'],
    housingDataDTO: ApplicationDataStateDTO['housingData']
  ): void {
    if (housingDataDTO?.since && housingData) {
      housingData.since = strDateToMonthYear(housingDataDTO.since);
    }
    if (housingDataDTO?.housing && housingData) {
      housingData.housing = this.getHousingEnum(housingDataDTO.housing);
    }
  }

  private setCompanyInformation(
    companyInformation: ApplicationDataState['companyInformation'],
    companyInformationDTO: ApplicationDataStateDTO['companyInformation']
  ): void {
    if (companyInformationDTO?.holder?.information && companyInformation?.holder) {
      const { since, creationDate, ...contractDTOData } = companyInformationDTO.holder.information;
      companyInformation.holder.information = {
        ...contractDTOData,
        ...(creationDate ? { creationDate: strDateToMonthYear(creationDate) } : {}),
        ...(since ? { since: strDateToMonthYear(since) } : {})
      } as HolderCompanyInformation;
    }
  }

  private setIncomeData(
    personalIncomes: ApplicationDataState['personalIncomes'],
    personalIncomesDTO: ApplicationDataStateDTO['personalIncome']
  ): void {
    if (personalIncomes && personalIncomesDTO) {
      personalIncomes.hasOtherIncomes = !!personalIncomesDTO.otherIncome || !!personalIncomesDTO.socialBenefits;
    }
  }

  private setBankDetails(
    bankDetails: ApplicationDataState['bankDetails'],
    bankDetailsDTO: ApplicationDataStateDTO['bankDetails']
  ): void {
    if (bankDetails && bankDetailsDTO) {
      bankDetails.isJointAccount = bankDetailsDTO.jointAccount;
      if (bankDetailsDTO.openedSince) {
        bankDetails.openedSince = strDateToMonthYear(bankDetailsDTO.openedSince);
      }
    }
  }

  private setStudentData(
    employmentSituation: ApplicationDataState['employmentSituation'],
    employmentSituationDTO: ApplicationDataStateDTO['employmentSituation']
  ): void {
    if (employmentSituation?.holder?.contract && employmentSituationDTO?.holder?.contract) {
      employmentSituation.holder.contract.since = strDateToMonthYear(employmentSituationDTO.holder.contract.since);
    }
    if (employmentSituation?.coHolder?.contract && employmentSituationDTO?.coHolder?.contract) {
      employmentSituation.coHolder.contract.since = strDateToMonthYear(employmentSituationDTO.coHolder.contract.since);
    }
  }

  private setLiberalData(
    employmentSituation: ApplicationDataState['employmentSituation'],
    employmentSituationDTO: ApplicationDataStateDTO['employmentSituation']
  ): void {
    if (
      employmentSituation?.holder?.contract &&
      (employmentSituationDTO?.holder?.contract as LiberalProfessionFormDataDTO).address
    ) {
      (employmentSituation.holder.contract as LiberalProfessionFormData).mailingAddress = (
        employmentSituationDTO?.holder?.contract as LiberalProfessionFormDataDTO
      ).address;
    }
  }

  private setPreSelectedServices(services: Service[] | NoValue): void {
    this.applicationStore.setImmutable({ services });
  }

  private getHousingEnum(housingKey: string): Housing | NoValue {
    const housingResult = Object.entries(Housing).find(([key]) => key === housingKey);
    return housingResult?.length ? housingResult[1] : null;
  }
}
