import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { StickyService } from '../../sticky.service';
import { InsurancesService } from '../insurances/insurances.service';
import { SimulationService } from './simulation.service';
import { map } from 'rxjs';
import { simulationResultToLoaSimulation, simulationResultToVatSimulation } from '../../helpers/simulation.mapper';
import { ApplicationStore } from '../application-store.service';
import { centsToEuros } from '../../helpers/currency.helper';
import { InsuranceData, PersonInsuranceData } from 'ngx-mobilite-material';
import { LegalNoticeService } from '../../legal-notice/legal-notice.service';

@Component({
  selector: 'fol-summary',
  templateUrl: './summary-simulation.component.html',
  styleUrls: ['./summary-simulation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummarySimulationComponent implements AfterViewInit, OnInit {
  @ViewChild('stickyButtonWrapper', { read: TemplateRef }) stickyButtonWrapper!: TemplateRef<HTMLElement>;
  isVat = false;
  insurances = this.applicationStore.instant('project')?.services;
  insuranceASM = this.insurances
    ?.filter(insurance => insurance.category === 'ASM')
    .map(
      insurance =>
        ({
          name: insurance.option,
          price: centsToEuros(insurance.monthlyAmount),
          death: insurance.option?.includes('D'),
          invalidity: insurance.option?.includes('I'),
          disease: insurance.option?.includes('M'),
          unemployment: insurance.option?.includes('C'),
          nbRisks: insurance.option?.length
        } as PersonInsuranceData)
    )[0];
  insurancePFI = this.insurances
    ?.filter(insurance => insurance.category === 'PFI')
    .map(insurance => ({ name: insurance.option, price: centsToEuros(insurance.monthlyAmount) } as InsuranceData))[0];
  insurancePAK = this.insurances
    ?.filter(insurance => insurance.category === 'PAK')
    .map(insurance => ({ name: insurance.option, price: centsToEuros(insurance.monthlyAmount) } as InsuranceData))[0];
  projectSimulation$ = this.simulationService.calculateSimulation().pipe(
    map(simulation => {
      this.isVat = ['VAT', 'VAP'].includes(simulation.product.productCode);
      return this.isVat ? simulationResultToVatSimulation(simulation) : simulationResultToLoaSimulation(simulation);
    })
  );

  backRoute = this.insurancesService.hasWrongASMImmutableInsurances() ? '../insurances' : '../bank-details';
  constructor(
    private readonly stickyService: StickyService,
    private readonly insurancesService: InsurancesService,
    private readonly simulationService: SimulationService,
    private readonly applicationStore: ApplicationStore,
    private readonly legalNoticeService: LegalNoticeService
  ) {}

  ngOnInit(): void {
    this.legalNoticeService.setType('product');
  }

  ngAfterViewInit(): void {
    this.stickyService.setStickyContent(this.stickyButtonWrapper);
  }
}
