import { ApplicationDataState, ImmutableState } from './application.model';
import { PartOrPro } from './part-pro-choice/part-pro-choice.model';
import { AloneOrCoHolder } from './alone-co-holder-choice/alone-co-holer-choice.model';
import { Civility, FamilySituation } from './personal-data-form/personal-data-form.model';
import { Housing } from './housing-form/housing-form.model';
import { BirthCountryChoice, NationalityChoice } from './birth-information-form/birth-information-form.model';
import { Activity } from './employment-situation-form/employment-situation-form.model';
import { PremisesAndBusiness } from './company/company.model';
import { Locality } from './birth-information/localities.service';
import { DebitingDate } from './bank-details/bank-details.component.model';

export const kennedy = '106 Av John Fitzgerald Kennedy';
export const MERIGNAC_LOCALITY: Locality = {
  name: 'MERIGNAC',
  zipCode: '33700',
  department: '33',
  inseeCode: '33281'
};
export const mockedEmployeeContract = {
  employerName: 'BNP PF',
  since: { month: '01', year: '2023' },
  contractType: { key: 'I', value: 'Contrat à Durée Indéterminée' },
  country: { key: 'FR', value: 'FRANCE' },
  employerAddress: '1 rue du moulin',
  employerCity: 'BOURGES',
  employerPhone: '0506060606',
  employerZipCode: { name: 'BOURGES', zipCode: '18000', department: '18', inseeCode: '18033' },
  isFirstJob: false,
  previousEmployerName: 'SFR',
  previousEmployedDuration: '20'
};
export const mockedHolderCompanyInfoData = {
  sirenNumber: '552049447',
  information: {
    companyName: 'SOCIETE NATIONALE SNCF',
    activityType: 'transports',
    legalStatus: { key: '7', value: 'SCI' },
    transplantCity: 'BOBIGNY',
    creationDate: { month: '01', year: '1955' },
    nafCode: '4910Z',
    capital: '1000000000',
    businessAssets: PremisesAndBusiness.TENANT,
    premises: PremisesAndBusiness.OWNER,
    since: { month: '01', year: '1955' },
    employeeNumber: '1'
  },
  contactDetails: {
    address: kennedy,
    additionalAddress: '',
    zipCode: MERIGNAC_LOCALITY,
    city: 'MERIGNAC',
    phone: '0555555555'
  }
};
export const mockedCoHolderCompanyInfoData = {
  sirenNumber: '552049447',
  information: {
    companyName: 'SOCIETE NATIONALE SNCF',
    premises: PremisesAndBusiness.OWNER,
    employeeNumber: '1'
  },
  contactDetails: {
    address: kennedy,
    additionalAddress: '',
    zipCode: '33700',
    city: 'MERIGNAC',
    phone: '0555555555'
  }
};
export const initialValue: ApplicationDataState = {
  partOrPro: PartOrPro.PART,
  aloneOrCoHolder: AloneOrCoHolder.CO_HOLDER,
  externalId: '03621a40-54eb-45bd-bca1-0776e2533283',
  identity: {
    holder: {
      civility: Civility.MADAM,
      firstName: 'Louise',
      lastName: 'Vierzon',
      birthName: 'Lobelle',
      mail: 'sdf@lkj.fr',
      mobile: '0789876655',
      familySituation: FamilySituation.MARRIED
    },
    coHolder: {
      civility: Civility.MISTER,
      firstName: 'Rene',
      lastName: 'Vierzon',
      mail: 'lkj@lkj.gf',
      mobile: '0789887789'
    }
  },
  bankDetails: {
    bic: 'CEPAFRPP333',
    iban: 'FR7613335000400491234567874',
    isJointAccount: true,
    debitingDate: DebitingDate.FIVE,
    openedSince: {
      month: '03',
      year: '2020'
    }
  },
  housingData: {
    housing: Housing.FAMILY_HOUSING,
    since: {
      month: '12',
      year: '2020'
    }
  },
  householdComposition: {
    nbChildren: 6
  },
  birthInformation: {
    holder: {
      birthCountryChoice: BirthCountryChoice.FRANCE,
      birthDate: {
        day: '12',
        month: '12',
        year: '2000'
      },
      franceChosen: {
        birthCity: { name: 'MERIGNAC', zipCode: '33700', department: '33', inseeCode: '33281' },
        birthDepartment: { key: '33', value: 'Gironde' }
      },
      nationalityChoice: NationalityChoice.FRENCH
    },
    coHolder: {
      birthCountryChoice: BirthCountryChoice.FRANCE,
      birthDate: {
        day: '12',
        month: '12',
        year: '2000'
      },
      franceChosen: {
        birthCity: { name: 'MERIGNAC', zipCode: '33700', department: '33', inseeCode: '33281' },
        birthDepartment: { key: '33', value: 'Gironde' }
      },
      nationalityChoice: NationalityChoice.FRENCH
    }
  },
  mailingAddress: {
    holder: {
      address: kennedy,
      additionalAddress: 'Porte 1',
      zipCode: MERIGNAC_LOCALITY,
      city: 'MERIGNAC'
    },
    coHolder: {
      address: kennedy,
      additionalAddress: 'Porte 2',
      zipCode: MERIGNAC_LOCALITY,
      city: 'MERIGNAC'
    }
  },
  companyInformation: {
    holder: mockedHolderCompanyInfoData,
    coHolder: mockedCoHolderCompanyInfoData
  },
  employmentSituation: {
    holder: {
      activity: Activity.PRIVATE_SECTOR,
      profession: { key: '30', value: 'Cadre Supérieur' },
      contract: mockedEmployeeContract
    },
    coHolder: {
      activity: Activity.LIBERAL_PROFESSIONS,
      profession: { key: '28', value: 'Infirmière libérale' },
      contract: {
        activityType: 'infirmiere',
        since: { month: '01', year: '2018' },
        mailingAddress: {
          address: kennedy,
          additionalAddress: '',
          zipCode: MERIGNAC_LOCALITY,
          city: 'MERIGNAC'
        },
        phone: '0666666666'
      }
    }
  },
  personalIncomes: {
    holderIncome: '70000',
    coHolderIncome: '45000',
    hasOtherIncomes: true,
    otherIncome: '12000',
    socialBenefits: '100'
  },
  costs: {
    alimony: '1020',
    housingTax: '999',
    otherTaxes: '9'
  },
  project: {
    subscribedMileage: 10000,
    subscribedMode: 'FAF',
    purchaseOrderNumber: null,
    deliveryDate: '2025-01-01',
    vehicle: {
      brand: null,
      energy: 'E',
      model: null,
      price: 4000000,
      registrationNumber: null,
      catalogEntryId: '240229',
      catalogId: null,
      family: 'Car',
      version: null,
      horsepower: null,
      dinHorsepower: null,
      carBody: null,
      carrierType: null,
      cylinder: null,
      serialNumber: null,
      commercial: null,
      secondHand: null,
      mileage: null,
      registrationFirstDate: null,
      accessoriesAmount: null,
      optionsAmount: null,
      vatChainBroken: null,
      useType: null,
      imported: null,
      vin: null
    },
    product: {
      professionalUsage: false,
      code: 'C',
      leaseDeposit: 0,
      pricing: '164'
    },
    services: [
      {
        monthlyAmount: 1500,
        category: 'ASM',
        formuleCode: null,
        option: 'DIM',
        policyHolder: 'Holder',
        subscribedMileage: 0
      },
      {
        monthlyAmount: 1400,
        category: 'PFI',
        formuleCode: null,
        option: 'GPFB',
        policyHolder: 'Holder',
        subscribedMileage: 0
      },
      {
        monthlyAmount: 1200,
        category: 'PAK',
        formuleCode: null,
        option: 'M',
        policyHolder: 'Holder',
        subscribedMileage: 0
      }
    ],
    downPayment: 0,
    downPaymentOrigin: null,
    downPaymentOriginLabel: null,
    duration: '49',
    offer: {
      totalFees: 0,
      residualValueAmount: null,
      residualValueRatio: null,
      payments: [
        {
          installment: 81991,
          installmentWithoutInsurance: 81900,
          duration: 49
        }
      ]
    }
  },
  dealer: {
    loginId: '7206607576Loc',
    webStoreId: '7206607576'
  }
};

export const initialImmutableValue: ImmutableState = {
  services: initialValue.project?.services
};

export const productionInitialValue = {
  project: initialValue.project,
  dealer: initialValue.dealer
};
