<div class='container' *ngIf='state$ | async | lowercase as activeStep'>

  <ng-container *ngIf="!isSupportingDocumentsState || (dataTransmission$ | async) as decision;else waitingScreen">
    <div class='row center text-center space-top-2 ' *ngIf="activeStep === 'application'">
      <span class='quit reminder'>{{ 'stepper.application.text' | translate }}</span>
    </div>
    <div class='title-container row center space-top-2' [ngClass]='{ signature: isSignatureState }'>
      <!-- title block -->
      <h1 *ngIf='!isSignatureState; else _signature'>{{ 'stepper.' + activeStep + (isSupportingDocumentsState ? '.' + decision : '')  + '.title' | translate }}</h1>
      <ng-template #_signature>
        <mat-icon>check</mat-icon>
        <h1>{{ 'stepper.' + activeStep + '.title' | translate }}</h1>
      </ng-template>
      <!-- title block -->
    </div>

    <div class='row center text-center space-top-4'  *ngIf="(isApplicationState || isSupportingDocumentsState) && decision==='r'">
      <span class='quit'>{{ 'stepper.supporting_documents.r.trust' | translate }}</span>
    </div>

    <div class='row center text-center space-top-2 ' *ngIf='isApplicationState || isSupportingDocumentsState'>
      <span class='quit' *ngIf="activeStep !== 'application'">{{ 'stepper.' + activeStep + (isSupportingDocumentsState ? '.' + decision : '') + '.text' | translate }}</span>
    </div>

    <div class='steps-container column center space-top-2' [ngClass]='{ reverse: isFinalState }'>
      <div class='row center text-center'><p>{{ 'stepper.' + activeStep + (isSupportingDocumentsState ? '.' + decision : '') + '.subtitle' | translate }}</p></div>
      <div class='column center step-wrapper space-top-1 gap-1' *ngIf="!(isSupportingDocumentsState && decision === RED_LIGHT.toLowerCase())">
        <fol-step *ngFor='let step of steps; let i = index' [num]='i + 1'
                    [title]='"stepper.steps." + step.lowercase | translate'
                    [validated]='validatedSteps.includes(step.enumValue)'
                    [active]='step.lowercase === activeStep'/>
      </div>
    </div>

    <fol-summary *ngIf='isSignatureState'/>


  </ng-container>
  <ng-template #waitingScreen>
    <ml-loader [title]="'stepper.supporting_documents.waiting_screen.title' | translate" [subtitle]="'stepper.supporting_documents.waiting_screen.subtitle' | translate"></ml-loader>
  </ng-template>
  <ng-template #stickyButtonWrapper>
    <div class="justify-sticky-buttons-center">
      <a mat-flat-button color='primary' [routerLink]="['../application/alone-co-holder-choice']"
         *ngIf='isApplicationState'>{{ 'stepper.buttons.' + activeStep | translate }}</a>
      <div class='column gap-1' *ngIf='isSignatureState'>
        <button mat-flat-button color='primary'>{{ 'stepper.buttons.' + activeStep + '.e-sign' | translate }}</button>
        <button mat-stroked-button color='primary'>{{ 'stepper.buttons.' + activeStep + '.dealership' | translate }}</button>
      </div>
    </div>
  </ng-template>
</div>
