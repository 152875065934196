import { Project } from '../application/application.model';
import { LegalNoticeInsurancesRequest, LegalNoticeProductRequest } from './legal-notice.model.component';

export const projectToLegalNoticeProductRequest = (project: Project): LegalNoticeProductRequest => {
  return {
    partnerName: 'CETELEM',
    productCode: project.product.code || 'C',
    vehicleCategory: project.vehicle.family || 'CAR',
    professional: project.product.professionalUsage,
    used: project.vehicle.secondHand || false
  };
};

export const projectToLegalNoticeInsurancesRequest = (
  productCode: string,
  insuranceCategory: string,
  insuranceOption: string
): LegalNoticeInsurancesRequest => {
  return {
    productCode,
    insuranceCategory,
    insuranceOption
  };
};
