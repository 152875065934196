import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LegalNoticeService } from './legal-notice.service';
import { TakeUntil } from 'ngx-mobilite-material';

@Component({
  selector: '@fol-legal-notice',
  templateUrl: './legal-notice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalNoticeComponent extends TakeUntil {
  legalNoticeContent$ = this.legalNoticeService.legalNoticeContent$;
  constructor(private readonly legalNoticeService: LegalNoticeService) {
    super();
  }
}
