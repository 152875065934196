import { NoValue } from 'ngx-mobilite-material';
import { Locality } from '../application/birth-information/localities.service';

const zeroX = /^0./;

export const someFieldIncludes = <T, F extends keyof T>(object: T, fields: F[], search: string): boolean =>
  fields
    .map(f => object[f])
    .join('◬')
    .toLowerCase()
    .includes(search.toLowerCase());

export const isDefined = <T>(val: unknown): val is T => val !== undefined && val !== null;

export const isValueLessThanTwoChars = (value: string | NoValue): boolean => {
  return (value || '').length < 2;
};

export const isValueEqualToZeroX = (value: string | NoValue): boolean => {
  return zeroX.test(value || '');
};

export const isLocality = (x: unknown): x is Locality => {
  return (
    !!x &&
    typeof x === 'object' &&
    'name' in x &&
    'zipCode' in x &&
    'department' in x &&
    'inseeCode' in x &&
    Object.keys(x).length === 4 &&
    !!x.department &&
    !!x.name &&
    !!x.zipCode &&
    !!x.inseeCode
  );
};
