import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output
} from '@angular/core';
import { TakeUntil } from '../../helpers/take-until.component';
import { AloneOrCoHolder } from '../alone-co-holder-choice/alone-co-holer-choice.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ensureBooleanOrNull } from '../../helpers/boolean.helper';
import {
  getDebounceTimeIfIsRecap,
  removeControlIfPresent,
  ToForm,
  toGroupValidator
} from '../../helpers/form/form.helper';
import { combineLatest, debounceTime, map, mergeMap, Observable, startWith, takeUntil } from 'rxjs';
import { ApplicationStore } from '../application-store.service';
import { PersonalIncomes, PersonalIncomesFormData } from './personal-incomes.model';
import { isValueEqualToZeroX, isValueLessThanTwoChars } from '../../helpers/object.helper';
import { eurosToCents, formatCurrency } from '../../helpers/currency.helper';
import { CurrencyFormatService } from '../../helpers/currency-format.service';
import { NoValue, YesNoTranslation } from 'ngx-mobilite-material';
import { TranslateService } from '@ngx-translate/core';

export type OtherIncomesGroup = Pick<PersonalIncomes, 'socialBenefits' | 'otherIncome'>;
type CoHolderIncomeField = ToForm<Pick<PersonalIncomes, 'coHolderIncome'>>;
type FormPersonalIncomes = ToForm<Pick<PersonalIncomes, 'holderIncome' | 'hasOtherIncomes'>> & {
  otherIncomesGroup?: FormGroup<ToForm<OtherIncomesGroup>>;
} & Partial<CoHolderIncomeField>;

@Component({
  selector: 'fol-personal-incomes-form',
  templateUrl: './personal-incomes-form.component.html',
  styleUrls: ['./personal-incomes-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalIncomesFormComponent extends TakeUntil implements OnInit {
  @Output() formFulfilled = new EventEmitter<PersonalIncomesFormData | null>();
  @Input() isRecap = false;
  hasPreFilled = false;
  userChoice: AloneOrCoHolder | NoValue = null;
  yesNoTranslations$: Observable<YesNoTranslation> = this.translateService.onTranslationChange.pipe(
    startWith(''),
    map(() => ({
      yes: this.translateService.instant('application.personal-incomes.yes-or-no.yes'),
      no: this.translateService.instant('application.personal-incomes.yes-or-no.no')
    }))
  );
  personalIncomesForm: FormGroup<FormPersonalIncomes> = new FormGroup<FormPersonalIncomes>({
    holderIncome: new FormControl<string>(
      formatCurrency(this.applicationStore.instant('personalIncomes')?.holderIncome, this.locale),
      Validators.required
    ),
    hasOtherIncomes: new FormControl<boolean | null>(
      ensureBooleanOrNull(this.applicationStore.instant('personalIncomes')?.hasOtherIncomes),
      Validators.required
    )
  });
  otherIncomesGroup = new FormGroup<ToForm<OtherIncomesGroup>>(
    {
      socialBenefits: new FormControl<string>(
        formatCurrency(this.applicationStore.instant('personalIncomes')?.socialBenefits, this.locale)
      ),
      otherIncome: new FormControl<string>(
        formatCurrency(this.applicationStore.instant('personalIncomes')?.otherIncome, this.locale)
      )
    },
    {
      validators: toGroupValidator(otherIncomesGroup => {
        return this.atLeastOneIncomeAboveZero(otherIncomesGroup.getRawValue());
      }, 'personal-incomes.one-field')
    }
  );
  computeIncomesFormState$ = new EventEmitter<void>();
  areIncomesFulfilled$: Observable<{ value: boolean }> = this.computeIncomesFormState$.pipe(
    startWith(null),
    mergeMap(() => {
      return combineLatest({
        holderIncome: this.personalIncomesForm.controls.holderIncome.valueChanges.pipe(
          startWith('' + this.personalIncomesForm.controls.holderIncome.value)
        ),
        ...(this.personalIncomesForm.controls.coHolderIncome
          ? {
              coHolderIncome: this.personalIncomesForm.controls.coHolderIncome.valueChanges.pipe(
                startWith('' + this.personalIncomesForm.controls.coHolderIncome.value)
              )
            }
          : {}),
        hasOtherIncome: this.personalIncomesForm.controls.hasOtherIncomes.valueChanges.pipe(
          startWith(this.personalIncomesForm.controls.hasOtherIncomes.value)
        )
      });
    }),
    map(({ holderIncome, coHolderIncome, hasOtherIncome }) => {
      if (this.isIncomesFulfilled(holderIncome, coHolderIncome)) {
        if (hasOtherIncome) {
          this.addOtherIncomesControl();
        } else {
          removeControlIfPresent(this.personalIncomesForm, 'otherIncomesGroup');
        }
        this.personalIncomesForm.updateValueAndValidity();
        return { value: true };
      } else {
        this.personalIncomesForm.get('hasOtherIncomes')?.setValue(null, { emitEvent: false });
        return { value: false };
      }
    })
  );

  @Input() set toggleForm(formToggled: boolean) {
    formToggled ? this.personalIncomesForm.disable() : this.personalIncomesForm.enable();
  }

  constructor(
    private readonly applicationStore: ApplicationStore,
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly currencyFormatService: CurrencyFormatService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userChoice = this.applicationStore.instant('aloneOrCoHolder');
    if (this.userChoice === AloneOrCoHolder.CO_HOLDER) {
      this.addCoHolderIncomeControl();
    }
    this.computeIncomesFormState$.emit();
    this.personalIncomesForm.statusChanges
      .pipe(takeUntil(this.destroy), debounceTime(getDebounceTimeIfIsRecap(this.isRecap)))
      .subscribe(() => {
        this.formFulfilled.emit(
          this.personalIncomesForm.valid
            ? this.dataFormatted(this.personalIncomesForm.getRawValue() as PersonalIncomes)
            : null
        );
      });
    if (this.isRecap) {
      this.personalIncomesForm.disable();
    }
  }

  private addCoHolderIncomeControl(): void {
    if (!this.personalIncomesForm.contains('coHolderIncome')) {
      this.personalIncomesForm.addControl(
        'coHolderIncome',
        new FormControl<string>(
          formatCurrency(this.applicationStore.instant('personalIncomes')?.coHolderIncome, this.locale),
          Validators.required
        )
      );
    }
  }

  private addOtherIncomesControl(): void {
    if (!this.personalIncomesForm.contains('otherIncomesGroup')) {
      this.personalIncomesForm.addControl('otherIncomesGroup', this.otherIncomesGroup);
      if (this.isRecap && !this.hasPreFilled) {
        this.personalIncomesForm.controls.otherIncomesGroup?.disable();
        this.hasPreFilled = true;
      }
    }
  }

  private isIncomesFulfilled(holderIncome: string | null, coHolderIncome: string | NoValue): boolean {
    return (
      !isValueLessThanTwoChars(holderIncome) &&
      !isValueEqualToZeroX(holderIncome) &&
      (this.userChoice === AloneOrCoHolder.ALONE ||
        (!isValueLessThanTwoChars(coHolderIncome) && !isValueEqualToZeroX(coHolderIncome)))
    );
  }

  private atLeastOneIncomeAboveZero(otherIncomesGroup: OtherIncomesGroup): boolean {
    return (
      this.isNotEqualToZero(otherIncomesGroup.socialBenefits) || this.isNotEqualToZero(otherIncomesGroup.otherIncome)
    );
  }

  private isNotEqualToZero(income: string | undefined): boolean {
    return ![NaN, 0].includes(Number(this.currencyFormatService.currencyToNumber(income || '0')));
  }

  private dataFormatted(data: PersonalIncomesFormData): PersonalIncomesFormData {
    return {
      ...data,
      holderIncome: eurosToCents(this.currencyFormatService.currencyToNumber(data.holderIncome)),
      coHolderIncome: eurosToCents(this.currencyFormatService.currencyToNumber(data.coHolderIncome)),
      otherIncomesGroup: data.otherIncomesGroup
        ? {
            otherIncome: eurosToCents(this.currencyFormatService.currencyToNumber(data.otherIncomesGroup.otherIncome)),
            socialBenefits: eurosToCents(
              this.currencyFormatService.currencyToNumber(data.otherIncomesGroup.socialBenefits)
            )
          }
        : data.otherIncomesGroup
    };
  }
}
