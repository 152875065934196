import {
  Activity,
  EmploymentSituationData
} from '../application/employment-situation-form/employment-situation-form.model';
import { Service } from '../application/application.model';
import { EmployeeFormData } from '../application/employment-situation-form/employee-form/employee-form-model';
import { NoValue } from 'ngx-mobilite-material';

export const isOnlyEligibleToDIMC = (activity: Activity | NoValue, contractTypeKey: string | NoValue): boolean => {
  return activity === Activity.PRIVATE_SECTOR && contractTypeKey === 'I';
};

export const isOnlyEligibleToDIM = (activity: Activity | NoValue, contractTypeKey: string | NoValue): boolean => {
  return (
    activity !== Activity.PENSIONER_OR_OTHER &&
    (activity === Activity.PUBLIC_SECTOR || activity !== Activity.PRIVATE_SECTOR || contractTypeKey !== 'I')
  );
};

export const isOnlyEligibleToD = (activity: Activity | NoValue): boolean => {
  return activity === Activity.PENSIONER_OR_OTHER;
};

export const INSURANCE_D = 'D';
export const INSURANCE_DIM = 'DIM';
export const INSURANCE_ASM = 'ASM';
export const INSURANCE_GPF = 'GPF';
export const INSURANCE_PAK = 'PAK';
export const MAX_PROJECT_COST_FOR_INSURANCE = 150000;

export const hasWrongInsurance = (
  insuranceCategory: string,
  insurancesPreSelected: Service[] | NoValue,
  employmentSituation: EmploymentSituationData | NoValue,
  isLessThanSixtyFive: boolean,
  isOlderThanEighty: boolean,
  isProjectCostMoreThan150000: boolean
): boolean => {
  const asmInsurances = insurancesPreSelected?.filter(service => service.category === insuranceCategory);

  if (asmInsurances?.length === 1) {
    const asmInsuranceOption = asmInsurances[0].option;
    if (isOlderThanEighty || isProjectCostMoreThan150000) {
      return true;
    }
    if (isLessThanSixtyFive) {
      return (
        isPrivateNotCDIOrPublicOrNotSalarieAndNotDIM(employmentSituation, asmInsuranceOption) ||
        isWithoutWorkOrRetiredOrOtherAndNotD(employmentSituation, asmInsuranceOption)
      );
    }
    return asmInsurances[0].option !== INSURANCE_D;
  }
  return !!asmInsurances?.length;
};

const isPrivateNotCDIOrPublicOrNotSalarieAndNotDIM = (
  employmentSituation: EmploymentSituationData | NoValue,
  insuranceOption: string | NoValue
): boolean => {
  return (
    insuranceOption !== INSURANCE_DIM &&
    insuranceOption !== INSURANCE_D &&
    isOnlyEligibleToDIM(
      employmentSituation?.activity,
      (employmentSituation?.contract as EmployeeFormData)?.contractType?.key
    )
  );
};

const isWithoutWorkOrRetiredOrOtherAndNotD = (
  employmentSituation: EmploymentSituationData | NoValue,
  insuranceOption: string | NoValue
): boolean => {
  return insuranceOption !== INSURANCE_D && isOnlyEligibleToD(employmentSituation?.activity);
};
