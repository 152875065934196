import { EmployeeFormData } from './employee-form/employee-form-model';
import { AgriculturalManagerOtherFormData } from './agricultural-manager-other-form/agricultural-manager-other-form.model';
import { StudentFormData } from './student-form/student-form.model';
import { LiberalProfessionFormData } from './liberal-profession-form/liberal-profession-form.model';
import { KeyValue } from 'ngx-mobilite-material';

export enum Activity {
  PRIVATE_SECTOR = 'SPR',
  PUBLIC_SECTOR = 'SPU',
  AGRICULTURAL_SECTOR = 'SAG',
  CRAFTMEN_TRADESMEN = 'ACO',
  LIBERAL_PROFESSIONS = 'PLI',
  PENSIONER_OR_OTHER = 'AUT'
}

export enum ContractType {
  EMPLOYE = 'EMP',
  AGRICULTURAL_MANAGER_OTHER = 'AMO',
  OTHER_STUDENT = 'STU',
  LIBERAL_PROFESSIONS = 'LIB',
  COMPANY_MANAGER = 'PRO'
}

export type EmploymentSituationData = {
  activity: Activity;
  profession: KeyValue;
  contract: EmployeeFormData | AgriculturalManagerOtherFormData | StudentFormData | LiberalProfessionFormData | null;
};

export type EmploymentSituation = {
  holder: EmploymentSituationData;
  coHolder: EmploymentSituationData;
};
