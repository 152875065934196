import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { SummarySimulationComponent } from './summary/summary-simulation.component';
import { SharedModule } from '../../shared/shared.module';
import { GraphicModule } from '../../graphic/graphic.module';
import { FormModule } from '../helpers/form/form.module';
import { PartProChoiceComponent } from './part-pro-choice/part-pro-choice.component';
import { AloneCoHolderChoiceComponent } from './alone-co-holder-choice/alone-co-holder-choice.component';
import { PersonalDataFormComponent } from './personal-data-form/personal-data-form.component';
import { HousingComponent } from './housing/housing.component';
import { HouseholdCompositionComponent } from './household-composition/household-composition.component';
import { PersonalIncomesComponent } from './personal-incomes/personal-incomes.component';
import { BirthInformationComponent } from './birth-information/birth-information.component';
import { BirthInformationFormComponent } from './birth-information-form/birth-information-form.component';
import { MailingAddressFormComponent } from './mailing-address-form/mailing-address-form.component';
import { MailingAddressComponent } from './mailing-address/mailing-address.component';
import { EmploymentSituationFormComponent } from './employment-situation-form/employment-situation-form.component';
import { EmploymentSituationComponent } from './employment-situation/employment-situation.component';
import { EmployeeFormComponent } from './employment-situation-form/employee-form/employee-form.component';
import { AgriculturalManagerOtherFormComponent } from './employment-situation-form/agricultural-manager-other-form/agricultural-manager-other-form.component';
import { StudentFormComponent } from './employment-situation-form/student-form/student-form.component';
import { LiberalProfessionFormComponent } from './employment-situation-form/liberal-profession-form/liberal-profession-form.component';
import { SirenNumberComponent } from './company/siren-number/siren-number.component';
import { CompanyInformationComponent } from './company/company-information/company-information.component';
import { CompanyContactDetailsComponent } from './company/company-contact-details/company-contact-details.component';
import { CostsComponent } from './costs/costs.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import { UserInformationModificationComponent } from './user-information-modification/user-information-modification.component';
import { HousingModificationFormComponent } from './user-information-modification/housing-modification-form/housing-modification-form.component';
import { HousingFormComponent } from './housing-form/housing-form.component';
import { UserInformationModificationFormComponent } from './user-information-modification/user-information-modification-form/user-information-modification-form.component';
import { GenericModificationFormComponent } from './user-information-modification/generic-modification-form/generic-modification-form.component';
import { EmploymentSituationModificationFormComponent } from './user-information-modification/employment-situation-modification-form/employment-situation-modification-form.component';
import { GenericContractFormComponent } from './employment-situation/generic-contract-form/generic-contract-form.component';
import { CompanyInformationModificationFormComponent } from './user-information-modification/company-information-modification-form/company-information-modification-form.component';
import { PersonalIncomesFormComponent } from './personal-incomes-form/personal-incomes-form.component';
import { PersonalIncomesModificationFormComponent } from './user-information-modification/personal-incomes-modification-form/personal-incomes-modification-form.component';
import { CostsFormComponent } from './costs-form/costs-form.component';
import { CostsModificationFormComponent } from './user-information-modification/costs-modification-form/costs-modification-form.component';
import { BankDetailsModificationFormComponent } from './user-information-modification/bank-details-modification-form/bank-details-modification-form.component';
import { BankDetailsFormComponent } from './bank-details-form/bank-details-form.component';
import {
  ActivityTypeInputComponent,
  AddressInputComponent,
  AutocompleteComponent,
  CurrencyInputComponent,
  DayMonthYearInputComponent,
  IbanInputComponent,
  PersonInsurancesInputComponent,
  LandlinePhoneInputComponent,
  MobilePhoneInputComponent,
  MonthYearInputComponent,
  NameInputComponent,
  NumberInputComponent,
  PhoneInputComponent,
  RadioSelectComponent,
  SelectComponent,
  TextInputComponent,
  YesNoInputComponent,
  ZipCodeAutocompleteComponent,
  PopinTriggerComponent,
  IconComponent,
  PopinPakInsuranceComponent,
  PopinPfiInsuranceComponent,
  PopinAsmInsuranceComponent,
  SummaryComponent,
  ControlRegexDirective,
  SummarySkeletonComponent,
  InsuranceCardSkeletonComponent,
  CustomLineSkeletonComponent
} from 'ngx-mobilite-material';
import { InsurancesComponent } from './insurances/insurances.component';
import { HouseholdCompositionFormComponent } from './household-composition-form/household-composition-form.component';
import { HouseholdCompositionModificationFormComponent } from './user-information-modification/household-composition-modification-form/household-composition-modification-form.component';
import { LegalNoticeComponent } from '../legal-notice/legal-notice.component';

const FORM_COMPONTENTS = [
  RadioSelectComponent,
  TextInputComponent,
  ActivityTypeInputComponent,
  SelectComponent,
  AddressInputComponent,
  MonthYearInputComponent,
  CurrencyInputComponent,
  ZipCodeAutocompleteComponent,
  PhoneInputComponent,
  YesNoInputComponent,
  NameInputComponent,
  MobilePhoneInputComponent,
  AutocompleteComponent,
  PersonInsurancesInputComponent,
  NumberInputComponent,
  LandlinePhoneInputComponent,
  DayMonthYearInputComponent,
  IbanInputComponent,
  IconComponent,
  ControlRegexDirective
];

@NgModule({
  declarations: [
    PersonalDataComponent,
    SummarySimulationComponent,
    PartProChoiceComponent,
    AloneCoHolderChoiceComponent,
    PersonalDataFormComponent,
    HousingComponent,
    HouseholdCompositionComponent,
    BirthInformationComponent,
    BirthInformationFormComponent,
    PersonalIncomesComponent,
    MailingAddressFormComponent,
    MailingAddressComponent,
    EmploymentSituationFormComponent,
    EmploymentSituationComponent,
    EmployeeFormComponent,
    AgriculturalManagerOtherFormComponent,
    StudentFormComponent,
    LiberalProfessionFormComponent,
    SirenNumberComponent,
    CompanyInformationComponent,
    CompanyContactDetailsComponent,
    CostsComponent,
    BankDetailsComponent,
    UserInformationModificationComponent,
    HousingModificationFormComponent,
    HousingFormComponent,
    UserInformationModificationFormComponent,
    GenericModificationFormComponent,
    EmploymentSituationModificationFormComponent,
    GenericContractFormComponent,
    CompanyInformationModificationFormComponent,
    PersonalIncomesFormComponent,
    PersonalIncomesModificationFormComponent,
    CostsFormComponent,
    CostsModificationFormComponent,
    BankDetailsModificationFormComponent,
    BankDetailsFormComponent,
    InsurancesComponent,
    HouseholdCompositionFormComponent,
    HouseholdCompositionModificationFormComponent,
    LegalNoticeComponent
  ],
  exports: [SummarySimulationComponent],
  imports: [
    CommonModule,
    SharedModule,
    GraphicModule,
    FormModule,
    ...FORM_COMPONTENTS,
    PopinTriggerComponent,
    PopinPakInsuranceComponent,
    PopinPfiInsuranceComponent,
    PopinAsmInsuranceComponent,
    SummaryComponent,
    SummarySkeletonComponent,
    InsuranceCardSkeletonComponent,
    CustomLineSkeletonComponent
  ]
})
export class ApplicationModule {}
